import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import Plot from 'react-plotly.js';
import { buildData } from 'util/customData';
import { buildLayout } from 'util/customLayout';

interface Props extends PanelProps<SimpleOptions> {}

const BoxWhisker = ({ data, options, width, height }: Props) => {
  console.log(data);
  if (data.series.length === 0) {
    return null;
  }

  const myData = buildData(data, options);
  const myLayout = buildLayout(data, options);

  return (
    <div className="rradar-bandw">
      <div className="chart-wrapper">
        <div className="chart">
          <Plot data={myData} layout={{ ...myLayout, width, height } as Plotly.Layout} />
        </div>
      </div>
    </div>
  );
};

export default BoxWhisker;
