import { PanelData } from '@grafana/data';
import { SimpleOptions } from 'types';
import { getValues } from './customData';

export const buildLayout = (panelData: PanelData, options: SimpleOptions) => {
  const { series } = panelData;
  const query = series[0];
  const extraGroupLabels = getValues(query, options.extraGroupField);
  const hasExtraGroup = extraGroupLabels.length > 0;
  const axisKey = options.orientation === 'v' ? 'yaxis' : 'xaxis';
  const layout: any = {
    title: `<b>${options.title}</b>`,
    yaxis: {},
    xaxis: {},
    [axisKey]: {
      autorange: true,
      showgrid: true,
      zeroline: true,
      gridcolor: 'rgb(255, 255, 255)',
      gridwidth: 1,
      zerolinecolor: 'rgb(255, 255, 255)',
      zerolinewidth: 2,
      type: options.yAxisType,
    },
    margin: {
      l: options.marginL,
      r: options.marginR,
      b: options.marginB,
      t: options.marginT,
    },
    paper_bgcolor: 'rgb(243, 243, 243)',
    plot_bgcolor: 'rgb(243, 243, 243)',
    showlegend: options.showlegend,
    boxmode: hasExtraGroup ? 'group' : 'overlay',
  };

  if (options.xTitle && options.xTitle !== '') {
    layout.xaxis.title = `<b>${options.xTitle}</b>`;
  }

  if (options.yTitle && options.yTitle !== '') {
    layout.yaxis.title = `<b>${options.yTitle}</b>`;
  }

  if (options.rangeMax && options.rangeMin) {
    layout[axisKey].range = [options.rangeMin, options.rangeMax];
    layout[axisKey].autorange = false;
  }

  return layout;
};
