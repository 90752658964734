import { DataFrame, PanelData } from '@grafana/data';
import { SimpleOptions } from 'types';

export const buildData = (panelData: PanelData, options: SimpleOptions) => {
  const { series } = panelData;
  const query = series[0];

  const valueIndex = query.fields.findIndex((f) => f.name === options.valueField);
  const mainIndex = query.fields.findIndex((f) => f.name === options.mainGroupField);
  const extraIndex = query.fields.findIndex((f) => f.name === options.extraGroupField);
  const referenceIndex = query.fields.findIndex((f) => f.name === options.referenceField);

  const mainGroupLabels = getValues(query, options.mainGroupField);
  const labels = getLabels(mainGroupLabels);
  const extraGroupLabels = getValues(query, options.extraGroupField);
  const referenceFieldValues = getValues(query, options.referenceField);

  const data: Plotly.Data[] = [];
  labels.forEach((label) => {
    const direction = options.orientation === 'v' ? ['y', 'x'] : ['x', 'y'];
    console.log(label);
    const result: any = {
      type: 'box',
      [direction[0]]: getFieldValues(query, label, valueIndex, mainIndex),
      name: label,
      boxpoints: options.boxpoints !== 'false' ? options.boxpoints : false,
      jitter: options.jitter,
      whiskerwidth: options.whiskerWidth,
      marker: {
        size: options.markerSize,
      },
      line: {
        width: options.lineWidth,
      },
      orientation: options.orientation,
    };
    if (extraGroupLabels.length > 0) {
      result[direction[1]] = getFieldValues(query, label, extraIndex, mainIndex);
    }
    if (referenceFieldValues.length > 0) {
      result.hovertemplate = `%{${[direction[0]]}:.2f}<extra>%{text}</extra>`;
      result.text = getFieldValues(query, label, referenceIndex, mainIndex);
    }
    data.push(result as Plotly.Data);
  });

  return data;
};

const getLabels = (labelValues: any[]) => {
  const labels: Set<string> = new Set();
  labelValues.forEach((value) => labels.add(`${value}`));
  return labels;
};

export const getValues = (query: DataFrame, fieldName: string) => {
  return query.fields.find((f) => f.name === fieldName)?.values.toArray() || [];
};

const getFieldValues = (query: DataFrame, label: string, fieldIndex: number, labelFieldIndex: number) => {
  return query.fields[fieldIndex].values
    .toArray()
    .filter((value, index) => label === `${query.fields[labelFieldIndex].values.toArray()[index]}`);
};
